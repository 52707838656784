(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/fraction-payment/assets/javascripts/fraction-payment-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/fraction-payment/assets/javascripts/fraction-payment-actions.js');
"use strict";


const {
  createAsyncThunk
} = RTK;
const {
  QS_FRACTION_DATA
} = svs.components.marketplaceShared.sharedConstants.constants;

const payFraction = createAsyncThunk('payFraction', async _ref => {
  let {
    userContextCallback,
    fractionData,
    memberId
  } = _ref;
  const {
    activatedDrawId,
    containerId,
    groupId,
    fractionPrice,
    productId
  } = fractionData;
  const fraction = svs.components.payment.createFraction.createFraction().setActivatedDrawId(activatedDrawId).setContainerId(containerId).setGroupId(groupId).setPrice(fractionPrice).setProductId(productId);
  const paymentBuilder = svs.components.wagerPaymentFlow.fractionFlow.createFractionPaymentFlow({
    config: {
      haltPaymentOnException: true
    },
    fraction
  });
  const [, error] = await svs.components.wagerPaymentFlow.executePayment.executePayment(paymentBuilder, {
    userContext: {
      returnQuery: {
        ["".concat(QS_FRACTION_DATA)]: "".concat(activatedDrawId, "_").concat(groupId)
      },
      callback: userContextCallback
    }
  });
  if (error) {
    throw error;
  }
  return {
    memberId,
    groupId,
    fractionsBought: 1,
    activatedDrawId
  };
});
setGlobal('svs.components.marketplaceData.fractionPayment.actions', {
  payFraction
});

 })(window);